import Vue from 'vue'
import Router from 'vue-router'

const Account = () => import('@/pages/Users/Account/main')
const Blog = () => import('@/pages/Blog/main')
const Contact = () => import('@/pages/Contact/main')
const FiltersAddEdit = () => import('@/pages/Filters/AddEdit/main')
const Home = () => import('@/pages/Home/main')
const Login = () => import('@/pages/Users/Login/main')
const PagePrivacy = () => import('@/pages/Static/Privacy/main')
const PageTerms = () => import('@/pages/Static/Terms/main')
const NotFound = () => import('@/pages/Static/NotFound/main')
const Reset = () => import('@/pages/Users/Reset/main')
const Sync = () => import('@/pages/Users/Sync/main')
Vue.use(Router)

export default new Router({
  mode: 'history',
  routes: [
    {
      path: '/users/reset/:token?',
      name: 'UsersReset',
      component: Reset,
      meta: {
        guards: 2 // public only
      }
    },
    {
      path: '/users/sync',
      name: 'UsersSync',
      component: Sync,
      meta: {
        guards: 1 // private only
      }
    },
    {
      path: '/users/account',
      name: 'UsersAccount',
      component: Account,
      meta: {
        guards: 1 // private only
      }
    },
    {
      path: '/users/:action?',
      name: 'UsersLogin',
      component: Login,
      meta: {
        guards: 2 // public only
      }
    },
    {
      path: '/contact',
      name: 'Contact',
      component: Contact,
      meta: {
        guards: 0 // public and private
      }
    },
    {
      path: '/blog',
      name: 'Blog',
      component: Blog,
      meta: {
        guards: 0 // public and private
      }
    },
    {
      path: '/pages/privacy',
      name: 'Privacy',
      component: PagePrivacy,
      meta: {
        guards: 0 // public and private
      }
    },
    {
      path: '/pages/terms',
      name: 'Terms',
      component: PageTerms,
      meta: {
        guards: 0 // public and private
      }
    },
    {
      path: '/filters/:id?',
      name: 'FiltersAddEdit',
      component: FiltersAddEdit,
      meta: {
        guards: 1 // private only
      }
    },
    {
      path: '/',
      name: 'Home',
      component: Home,
      meta: {
        guards: 1 // private only
      }
    },
    {
      path: '/404',
      name: 'NotFound',
      component: NotFound,
      meta: {
        guards: 0 // private and public
      }
    },
    {
      path: '*',
      name: 'General',
      redirect: '/404'
    }
  ],
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    }
    return { x: 0, y: 0 }
  }
})
