import Vue from 'vue'
import VueI18n from 'vue-i18n'
import { localize } from 'vee-validate'
import store from '@/config/store'
import en from '@/locales/en'

Vue.use(VueI18n)

function customPluralization (_choice, _choicesLength) {
  _choice = Math.abs(_choice)

  if (_choicesLength === 2) {
    return _choice
      ? _choice > 1
        ? 1
        : 0
      : 1
  }

  return _choice ? Math.min(_choice, _choicesLength - 1) : 0
}

export const i18n = new VueI18n({
  locale: 'en',
  fallbackLocale: 'en',
  messages: { en },
  pluralizationRules: {
    'cs-CZ': customPluralization,
    'ru-RU': customPluralization
  }
})

const loadedLanguages = ['en'] // our default language that is pre-loaded
localize('en', en)

function setI18nLanguage (languageObject, translations) {
  i18n.locale = languageObject.id || 'en'

  if (translations) {
    i18n.setLocaleMessage(languageObject.id, translations)

    localize(languageObject.id, translations)
  } else {
    localize(languageObject.id)
  }

  const html = document.querySelector('html')
  html.setAttribute('lang', languageObject.id || '')
  html.setAttribute('dir', languageObject.direction || '')

  Vue.ls.set('language', languageObject)

  store.commit('setLanguage', languageObject)
  return languageObject
}

export function loadLanguageAsync (languageObject) {
  if (languageObject && languageObject.id && i18n.locale !== languageObject.id) {
    if (!loadedLanguages.includes(languageObject.id)) {
      return import(/* webpackChunkName: "lang-[request]" */ `@/locales/${languageObject.id}`).then(msgs => {
        loadedLanguages.push(languageObject.id)

        return setI18nLanguage(languageObject, msgs.default)
      })
    }

    return Promise.resolve(setI18nLanguage(languageObject))
  }
  return Promise.resolve(languageObject)
}

export const languages = [
  { id: 'en', name: 'English', direction: 'ltr' },
  { id: 'ro-RO', name: 'Română', direction: 'ltr' },
  { id: 'es-ES', name: 'Español', direction: 'ltr' },
  { id: 'fr-FR', name: 'Français', direction: 'ltr' },
  { id: 'ru-RU', name: 'Русский', direction: 'ltr' },
  { id: 'de-DE', name: 'Deutsch', direction: 'ltr' },
  { id: 'it-IT', name: 'Italiano', direction: 'ltr' },
  { id: 'cs-CZ', name: 'Čeština', direction: 'ltr' },
  { id: 'sr-SP', name: 'Srpski', direction: 'ltr' }
]
