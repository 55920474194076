import has from 'lodash.has'
import transform from 'lodash.transform'
import filterSchema from '@/constants/filter'

export default {
  transformFilterToJS (dbItem) {
    const newItem = transform(dbItem, (accumulator, value, key) => {
      let newKey = key
      const last = key.substring(key.length - 4, key.length)
      if (last === '_min' || last === '_max') {
        newKey = key.substring(0, key.length - 4)

        if (!has(accumulator, newKey)) {
          accumulator[newKey] = []
        }

        if (value !== null) {
          if (last === '_min') {
            accumulator[newKey][0] = value
          } else if (last === '_max') {
            accumulator[newKey][1] = value
          }
        }
      } else {
        accumulator[newKey] = value
      }
    }, {})

    // calculate the dirtiness
    newItem.dirty = 0
    filterSchema.SCHEMA.forEach((attribute) => {
      newItem.dirty += this.calculateDirtyness(attribute, newItem)
    })

    return newItem
  },
  calculateDirtyness (attribute, newItem) {
    let dirty = 0
    if (attribute.type === 'object' && has(attribute, 'SCHEMA')) {
      attribute.SCHEMA.forEach((attr) => {
        dirty += this.calculateDirtyness(attr, newItem)
      })
    } else if (attribute.group !== undefined && attribute.group === 'filter' && has(newItem, attribute.name) &&
      newItem[attribute.name] !== null && newItem[attribute.name].length) {
      dirty++
    }

    return dirty
  },
  transformFilterToDB (jsItem) {
    return transform(jsItem, (accumulator, value, key) => {
      if (value instanceof Array) {
        switch (key) {
          case 'specialties':
            accumulator[key] = value
            break
          default:
            accumulator[key + '_min'] = value[0] !== undefined ? value[0] : null
            accumulator[key + '_max'] = value[1] !== undefined ? value[1] : null
        }
      } else {
        accumulator[key] = value
      }
    }, {})
  },
  querablePromise (promise) {
    // Don't modify any promise that has been already modified.
    if (promise.isResolved) return promise

    // Set initial state
    let isPending = true
    let isRejected = false
    let isFulfilled = false

    // Observe the promise, saving the fulfillment in a closure scope.
    const result = promise.then(
      function (v) {
        isFulfilled = true
        isPending = false
        return v
      },
      function (e) {
        isRejected = true
        isPending = false
        throw e
      }
    )

    result.isFulfilled = function () { return isFulfilled }
    result.isPending = function () { return isPending }
    result.isRejected = function () { return isRejected }

    return result
  }
}
