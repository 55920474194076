import find from 'lodash.find'
import get from 'lodash.get'
import has from 'lodash.has'
import set from 'lodash.set'
import Vue from 'vue'
import Vuex from 'vuex'
import findIndex from 'lodash.findindex'
import moment from 'moment/moment'
import { loadLanguageAsync, languages } from '@/config/i18n'

import filterSchema from '@/constants/filter'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    // system objects
    countries: null,
    // our objects
    token: null,
    token_exp: null,
    token_refresh_promise: null,
    user: null,
    language: null,
    device: null,
    players_own: null,
    players_bookmarked: null,
    filter: null,
    filters: null,
    redirect_path: null,
    sw_registration: null,
    get_device_ready: false
  },
  actions: {
    token_check ({ commit, state }) {
      if (!Vue.ls.get('remember')) {
        return Promise.resolve()
      }

      const now = moment.utc()
      if (state.token_exp && now < moment.utc(state.token_exp * 1000)) {
        return Promise.resolve()
      }

      if (!state.token_refresh_promise || !state.token_refresh_promise.isPending()) {
        const data = {
          token: state.token
        }

        const promise = Vue.http.patch(
          'v1/auth/refresh',
          data,
          null
        ).then(
          (response) => {
            if (response.body && response.body.token) {
              commit('setToken', response.body)

              return response.body
            }

            return Promise.reject(response)
          },
          (response) => {
            return Promise.reject(response)
          }
        )

        commit('setTokenRefreshPromise', Vue.helpers.querablePromise(promise))
      }

      return state.token_refresh_promise
    },
    user_login ({ commit }, credentials) {
      return Vue.http.post(
        'v1/auth/login',
        credentials
      ).then(
        (response) => {
          if (response.body && response.body.user && response.body.token) {
            commit('setUser', response.body)

            return response.body
          }

          commit('setUser', null)

          return Promise.reject(response)
        },
        (response) => {
          commit('setUser', null)

          return Promise.reject(response)
        }
      )
    },
    user_logout ({ commit }) {
      commit('setUser', null)

      return Promise.resolve()
    },
    user_register ({ commit }, user) {
      return Vue.http.post(
        'v1/auth/register',
        user
      ).then(
        (response) => {
          commit('setUser', null)

          if (response.body && response.body.id) {
            return response.body
          }

          return Promise.reject(response)
        },
        (response) => {
          commit('setUser', null)

          return Promise.reject(response)
        }
      )
    },
    user_recover ({ commit }, data) {
      return Vue.http.post(
        'v1/auth/forgot',
        data
      ).then(
        (response) => {
          if (response.body && response.body.success) {
            return response.body
          }

          return Promise.reject(response)
        },
        response => Promise.reject(response)
      )
    },
    user_reset ({ commit }, data) {
      return Vue.http.post(
        'v1/auth/reset',
        data
      ).then(
        (response) => {
          if (response.body && response.body.success) {
            return response.body
          }

          return Promise.reject(response)
        },
        response => Promise.reject(response)
      )
    },
    user_sync ({ commit, dispatch, state }) {
      if (!state.user || !state.user.id || !state.token) {
        // simulate a http response
        const response = {
          body: {
            errors: {
              session: 'expired'
            }
          }
        }
        return Promise.reject(response)
      }

      commit('setGetDeviceReady')

      return dispatch('token_check').then(
        () => {
          const options = {
            headers: {
              Authorization: `Bearer ${state.token}`
            }
          }

          return Vue.http.patch(
            `v1/users/${state.user.id}/sync`,
            null,
            options
          ).then(
            (response) => {
              if (response.body && response.body.id) {
                const data = {
                  token: state.token,
                  user: response.body
                }
                commit('setUser', data)

                return response.body
              }

              return Promise.reject(response)
            },
            response => Promise.reject(response)
          )
        }
      ).catch(response => Promise.reject(response))
    },
    user_authorize_init ({ dispatch, state }, data) {
      if (!state.user || !state.user.id || !state.token) {
        // simulate a http response
        const response = {
          body: {
            errors: {
              session: 'expired'
            }
          }
        }
        return Promise.reject(response)
      }

      return dispatch('token_check').then(
        () => {
          const options = {
            headers: {
              Authorization: `Bearer ${state.token}`
            }
          }

          return Vue.http.post(
            `v1/users/${state.user.id}/chpp`,
            data,
            options
          ).then(
            (response) => {
              if (response.body && response.body.url) {
                return response.body
              }

              return Promise.reject(response)
            },
            response => Promise.reject(response)
          )
        }
      ).catch(response => Promise.reject(response))
    },
    user_authorize_store ({ dispatch, state }, data) {
      if (!state.user || !state.user.id || !state.token) {
        // simulate a http response
        const response = {
          body: {
            errors: {
              session: 'expired'
            }
          }
        }
        return Promise.reject(response)
      }

      return dispatch('token_check').then(
        () => {
          const options = {
            headers: {
              Authorization: `Bearer ${state.token}`
            }
          }

          return Vue.http.patch(
            `v1/users/${state.user.id}/chpp`,
            data,
            options
          ).then(
            response => true,
            response => Promise.reject(response)
          )
        }
      ).catch(response => Promise.reject(response))
    },
    user_update ({ commit, dispatch, state }, data) {
      if (!state.user || !state.user.id || !state.token) {
        // simulate a http response
        const response = {
          body: {
            errors: {
              session: 'expired'
            }
          }
        }
        return Promise.reject(response)
      }

      return dispatch('token_check').then(
        () => {
          const options = {
            headers: {
              Authorization: `Bearer ${state.token}`
            }
          }

          return Vue.http.patch(
            `v1/users/${state.user.id}`,
            data,
            options
          ).then(
            (response) => {
              if (response.body && response.body.id) {
                const data = {
                  token: state.token,
                  user: response.body
                }
                commit('setUser', data)

                return response.body
              }

              return Promise.reject(response)
            },
            response => Promise.reject(response)
          )
        }
      ).catch(response => Promise.reject(response))
    },
    user_device_register ({ commit, dispatch, state }, token) {
      if (!state.user || !state.user.id || !state.token) {
        // do nothing if user is not logged in
        // Success is not handled in any way
        return true
      }

      if (state.user.devices && state.user.devices.length && findIndex(state.user.devices, ['token', token]) >= 0) {
        // do nothing, user is has already registered this device
        // Success is not handled in any way
        return true
      }

      return dispatch('token_check').then(
        () => {
          const options = {
            headers: {
              Authorization: `Bearer ${state.token}`
            }
          }

          return Vue.http.post(
            `v1/users/${state.user.id}/devices`,
            { token, endpoint: 1 }, // TODO: should remove FCM hard-code
            options
          ).then(
            (response) => {
              if (response.body) {
                commit('updateUserDevices', response.body)
                // Success is not handled in any way
                return true
              }

              return Promise.reject(response)
            },
            response => Promise.reject(response)
          )
        }
      ).catch(response => Promise.reject(response))
    },
    user_device_unregister ({ commit, dispatch, state }, token) {
      if (!state.user || !state.user.id || !state.token) {
        // simulate a http response
        const response = {
          body: {
            errors: {
              session: 'expired'
            }
          }
        }
        return Promise.reject(response)
      }

      return dispatch('token_check').then(
        () => {
          const options = {
            headers: {
              Authorization: `Bearer ${state.token}`
            }
          }

          return Vue.http.delete(
            `v1/users/${state.user.id}/devices/${token}`,
            options
          ).then(
            (response) => {
              if (response.body) {
                commit('updateUserDevices', response.body)

                // Success is not handled in any way
                return true
              }

              return Promise.reject(response)
            },
            response => Promise.reject(response)
          )
        }
      ).catch(response => Promise.reject(response))
    },
    user_delete ({ commit, dispatch, state }) {
      if (!state.user || !state.user.id || !state.token) {
        // simulate a http response
        const response = {
          body: {
            errors: {
              session: 'expired'
            }
          }
        }
        return Promise.reject(response)
      }

      return dispatch('token_check').then(
        () => {
          const options = {
            headers: {
              Authorization: `Bearer ${state.token}`
            }
          }

          return Vue.http.delete(
            `v1/users/${state.user.id}`,
            options
          ).then(
            response => response.body,
            response => Promise.reject(response)
          )
        }
      ).catch(response => Promise.reject(response))
    },
    device_get ({ commit, state }) {
      if (!Vue.messaging) {
        commit('setDevice', false)
        return Promise.reject(new Error('Browser does not support FCM push notification'))
      }

      let options
      if (state.sw_registration) {
        options = { serviceWorkerRegistration: state.sw_registration }
      }

      return Vue.messaging.getToken(options)
        .then(
          (response) => {
            commit('setDevice', response)

            if (!response) {
              return Promise.reject(response)
            }
            return response
          },
          (response) => {
            if (response && response.code === 'messaging/notifications-blocked') {
              commit('setDevice', false)
            } else {
              commit('setDevice', null)
            }

            return Promise.reject(response)
          }
        )
    },
    device_set ({ commit }, token) {
      commit('setDevice', token)
    },
    players_get_own ({ commit, dispatch, state }) {
      if (!state.user || !state.user.id || !state.token) {
        // simulate a http response
        const response = {
          body: {
            errors: {
              session: 'expired'
            }
          }
        }
        return Promise.reject(response)
      }

      return dispatch('token_check').then(
        () => {
          const options = {
            headers: {
              Authorization: `Bearer ${state.token}`
            }
          }

          return Vue.http.patch(
            `v1/users/${state.user.id}/players/sync`,
            null,
            options
          ).then(
            (response) => {
              if (response.body) {
                commit('setPlayersOwn', response.body)

                return response.body
              }

              commit('setPlayersOwn', [])
              return Promise.reject(response)
            },
            response => Promise.reject(response)
          )
        }
      ).catch((response) => {
        return Promise.reject(response)
      })
    },
    players_get_bookmarked ({ commit, dispatch, state }) {
      if (!state.user || !state.user.id || !state.token) {
        // simulate a http response
        const response = {
          body: {
            errors: {
              session: 'expired'
            }
          }
        }
        return Promise.reject(response)
      }

      return dispatch('token_check').then(
        () => {
          const options = {
            headers: {
              Authorization: `Bearer ${state.token}`
            }
          }

          return Vue.http.get(
            `v1/users/${state.user.id}/players/bookmarked`,
            options
          ).then(
            (response) => {
              if (response.body) {
                commit('setPlayersBookmarked', response.body)

                return response.body
              }

              commit('setPlayersBookmarked', [])
              return Promise.reject(response)
            },
            response => Promise.reject(response)
          )
        }
      ).catch(response => Promise.reject(response))
    },
    players_get_filter ({ commit, dispatch, state }, id) {
      if (!state.user || !state.user.id || !state.token) {
        // simulate a http response
        const response = {
          body: {
            errors: {
              session: 'expired'
            }
          }
        }
        return Promise.reject(response)
      }

      return dispatch('token_check').then(
        () => {
          const options = {
            headers: {
              Authorization: `Bearer ${state.token}`
            }
          }

          return Vue.http.get(
            `v1/users/${state.user.id}/filters/${id}/players`,
            options
          ).then(
            (response) => {
              if (response.body) {
                return response.body
              }

              return Promise.reject(response)
            },
            response => Promise.reject(response)
          )
        }
      ).catch(response => Promise.reject(response))
    },
    players_bookmark ({ commit, dispatch, state }, data) {
      if (!state.user || !state.user.id || !state.token) {
        // simulate a http response
        const response = {
          body: {
            errors: {
              session: 'expired'
            }
          }
        }
        return Promise.reject(response)
      }

      return dispatch('token_check').then(
        () => {
          const id = data.id

          const options = {
            headers: {
              Authorization: `Bearer ${state.token}`
            }
          }

          return Vue.http.patch(
            `v1/users/${state.user.id}/players/${id}/bookmark`,
            data,
            options
          ).then(
            (response) => {
              if (response.body) {
                commit('setPlayersBookmarked', response.body)

                return response.body
              }

              return Promise.reject(response)
            },
            response => Promise.reject(response)
          )
        }
      ).catch(response => Promise.reject(response))
    },
    players_ignore ({ commit, dispatch, state }, data) {
      if (!state.user || !state.user.id || !state.token) {
        // simulate a http response
        const response = {
          body: {
            errors: {
              session: 'expired'
            }
          }
        }
        return Promise.reject(response)
      }

      return dispatch('token_check').then(
        () => {
          const id = data.id
          const filterId = data.filterId

          const options = {
            headers: {
              Authorization: `Bearer ${state.token}`
            }
          }

          return Vue.http.patch(
            `v1/users/${state.user.id}/filters/${filterId}/players/${id}/ignore`,
            data,
            options
          ).then(
            (response) => {
              if (response.body) {
                return response.body
              }

              return Promise.reject(response)
            },
            response => Promise.reject(response)
          )
        }
      ).catch(response => Promise.reject(response))
    },
    filters_get ({ commit, dispatch, state }) {
      if (!state.user || !state.user.id || !state.token) {
        // simulate a http response
        const response = {
          body: {
            errors: {
              session: 'expired'
            }
          }
        }
        return Promise.reject(response)
      }

      return dispatch('token_check').then(
        () => {
          const options = {
            headers: {
              Authorization: `Bearer ${state.token}`
            }
          }

          return Vue.http.get(
            `v1/users/${state.user.id}/filters`,
            options
          ).then(
            (response) => {
              if (response.body) {
                const filterObjs = response.body.map((item) => {
                  return Vue.helpers.transformFilterToJS(item)
                })
                commit('setFilters', filterObjs)
                return response.body
              }

              commit('setFilters', [])
              return Promise.reject(response)
            },
            response => Promise.reject(response)
          )
        }
      ).catch(response => Promise.reject(response))
    },
    filter_get ({ commit, dispatch, state }, id) {
      if (!state.user || !state.user.id || !state.token) {
        // simulate a http response
        const response = {
          body: {
            errors: {
              session: 'expired'
            }
          }
        }
        return Promise.reject(response)
      }

      const defaultFilter = {
        dirty: 0
      }
      filterSchema.SCHEMA.forEach((attribute) => {
        if (attribute.type === 'object') {
          const obj = {}
          attribute.SCHEMA.forEach((subAttr) => {
            obj[subAttr.name] = get(subAttr, 'default', null)
          })
          defaultFilter[attribute.name] = obj
        } else {
          defaultFilter[attribute.name] = get(attribute, 'default', null)
        }
      })

      if (!id) {
        commit('setFilter', defaultFilter)

        return defaultFilter
      }

      return dispatch('token_check').then(
        () => {
          const options = {
            headers: {
              Authorization: `Bearer ${state.token}`
            }
          }

          return Vue.http.get(
            `v1/users/${state.user.id}/filters/${id}`,
            options
          ).then(
            (response) => {
              if (response.body) {
                const obj = Vue.helpers.transformFilterToJS(response.body)
                commit('setFilter', obj)

                return response.body
              }

              commit('setFilter', defaultFilter)
              return Promise.reject(response)
            },
            (response) => {
              commit('setFilter', defaultFilter)
              return Promise.reject(response)
            }
          )
        }
      ).catch(response => Promise.reject(response))
    },
    filter_update ({ commit, dispatch, state }, data) {
      if (!state.user || !state.user.id || !state.token) {
        // simulate a http response
        const response = {
          body: {
            errors: {
              session: 'expired'
            }
          }
        }
        return Promise.reject(response)
      }

      return dispatch('token_check').then(
        () => {
          const options = {
            headers: {
              Authorization: `Bearer ${state.token}`
            }
          }

          const method = data.id ? 'patch' : 'post'
          const url = data.id ? `v1/users/${state.user.id}/filters/${data.id}` : `v1/users/${state.user.id}/filters`

          return Vue.http[method](
            url,
            data,
            options
          ).then(
            (response) => {
              if (response.body) {
                const obj = Vue.helpers.transformFilterToJS(response.body)
                commit('updateInFilters', obj)

                return response.body
              }

              return Promise.reject(response)
            },
            (response) => {
              return Promise.reject(response)
            }
          )
        }
      ).catch(response => Promise.reject(response))
    },
    filter_delete ({ commit, dispatch, state }, id) {
      if (!state.user || !state.user.id || !state.token) {
        // simulate a http response
        const response = {
          body: {
            errors: {
              session: 'expired'
            }
          }
        }
        return Promise.reject(response)
      }

      return dispatch('token_check').then(
        () => {
          const options = {
            headers: {
              Authorization: `Bearer ${state.token}`
            }
          }

          return Vue.http.delete(
            `v1/users/${state.user.id}/filters/${id}`,
            options
          ).then(
            (response) => {
              if (response.body) {
                commit('removeFromFilters', response.body)

                return response.body
              }

              return Promise.reject(response)
            },
            response => Promise.reject(response)
          )
        }
      ).catch(response => Promise.reject(response))
    },
    filter_search ({ commit, dispatch, state }, id) {
      if (!state.user || !state.user.id || !state.token) {
        // simulate a http response
        const response = {
          body: {
            errors: {
              session: 'expired'
            }
          }
        }
        return Promise.reject(response)
      }

      return dispatch('token_check').then(
        () => {
          const options = {
            headers: {
              Authorization: `Bearer ${state.token}`
            }
          }

          return Vue.http.get(
            `v1/users/${state.user.id}/filters/${id}/search`,
            options
          ).then(
            (response) => {
              if (response.body) {
                const obj = Vue.helpers.transformFilterToJS(response.body)
                commit('updateInFilters', obj)

                return response.body
              }

              return Promise.reject(response)
            },
            response => Promise.reject(response)
          )
        }
      ).catch(response => Promise.reject(response))
    },
    countries_get ({ commit, dispatch, state }) {
      if (!state.user || !state.user.id || !state.token) {
        // simulate a http response
        const response = {
          body: {
            errors: {
              session: 'expired'
            }
          }
        }
        return Promise.reject(response)
      }

      return dispatch('token_check').then(
        () => {
          const options = {
            headers: {
              Authorization: `Bearer ${state.token}`
            }
          }

          return Vue.http.get(
            'v1/countries',
            options
          ).then(
            (response) => {
              if (response.body) {
                commit('setCountries', response.body)

                return response.body
              }

              commit('setCountries', [])
              return Promise.reject(response)
            },
            response => Promise.reject(response)
          )
        }
      ).catch(response => Promise.reject(response))
    },
    set_redirect ({ commit }, path) {
      commit('setRedirectPath', path)
    },
    update_sw_registration ({ commit }, registration) {
      commit('setSWRegistration', registration)
      commit('setGetDeviceReady')
    }
  },
  mutations: {
    // our mutations
    setUser (state, data) {
      if (!data) {
        Vue.ls.remove('token')
        Vue.ls.remove('exp')
        Vue.ls.remove('user')

        state.token = data
        state.token_exp = data
        state.user = data
      } else {
        state.token = data.token
        state.token_exp = data.exp
        state.user = data.user

        Vue.ls.set('token', data.token) // expires in 2 hours
        Vue.ls.set('exp', data.exp)
        Vue.ls.set('user', data.user)

        // load the correct language
        let languageObject = Vue.ls.get('language', {})
        if (state.user && state.user.locale) {
          const lang = find(languages, ['id', state.user.locale])
          if (lang !== undefined) {
            languageObject = lang
          }
        }
        if (languageObject.id) {
          loadLanguageAsync(languageObject).catch(() => {
            console.log(`Failed to load ${languageObject.id} language`) // eslint-disable-line
          })
        }

        // set the GA userID property
        Vue.$gtag.set({
            user_id: get(data, 'user.username', 'anonymous')
          }
        )
      }
    },
    setLanguage (state, data) {
      state.language = data
    },
    setToken (state, data) {
      if (!data) {
        Vue.ls.remove('token')
        Vue.ls.remove('exp')
        Vue.ls.remove('user')

        state.token = data
        state.token_exp = data
        state.user = data
      } else {
        state.token = data.token
        state.token_exp = data.exp

        Vue.ls.set('token', data.token) // expires in 2 hours
        Vue.ls.set('exp', data.exp)
      }
    },
    setTokenRefreshPromise (state, data) {
      state.token_refresh_promise = data
    },
    updateUserDevices (state, data) {
      if (state.user) {
        state.user.devices = data
        Vue.ls.set('user', state.user)
      }
    },
    setDevice (state, data) {
      state.device = data
    },
    setPlayersOwn (state, data) {
      state.players_own = data
    },
    setPlayersBookmarked (state, data) {
      state.players_bookmarked = data
    },
    setFilter (state, data) {
      state.filter = data
    },
    updateFilter (state, { property, value }) {
      if (has(state.filter, property)) {
        // calculate dirtiness
        const index = findIndex(filterSchema.SCHEMA, { name: property, group: 'filter' })
        if (value !== null && value.length) {
          if (index >= 0 && (state.filter[property] === null || !state.filter[property].length)) {
            state.filter.dirty++
          }
        } else if (state.filter.dirty > 0) {
          if (index >= 0 && state.filter[property] !== null && state.filter[property].length) {
            state.filter.dirty--
          }
        }

        // always update this at the end of the checks
        set(state.filter, property, value)
      }
    },
    setFilters (state, data) {
      state.filters = data
    },
    updateInFilters (state, data) {
      if (state.filters) {
        const index = findIndex(state.filters, ['id', data.id])
        if (~index) {
          state.filters.splice(index, 1, data)
        } else {
          state.filters.push(data)
        }
      }
    },
    removeFromFilters (state, data) {
      if (state.filters) {
        const newFilters = state.filters.filter((item) => {
          return item.id !== data.id
        })
        state.filters = newFilters
      }
    },
    setCountries (state, data) {
      state.countries = data

      if (data && data.length) {
        Vue.ls.set('countries', data)
      }
    },
    setRedirectPath (state, data) {
      state.redirect_path = data
    },
    setSWRegistration (state, data) {
      state.sw_registration = data
    },
    setGetDeviceReady (state) {
      state.get_device_ready = state.sw_registration && state.user
    }
  }
})
