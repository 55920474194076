
export default {
  computed: {
    user () {
      return this.$store.state.user
    },
    country () {
      return this.user && this.user.ht_user ? this.user.ht_user.ht_country : {}
    },
    is_push_blocked () {
      return !this.$messaging || (this.$store.state.user && (this.$store.state.device === false))
    }
  },
  methods: {
    printErrors (response, observer, field = 'general') {
      let reasons = { general: ['unknown'] }

      if (response) {
        if (response.status === 0) {
          reasons = { general: ['network'] }
        } else if (response.status === 503 || response.status === 401) {
          // in this case show a pop-up
          this.handleErrors(response)
          return
        } else if (response.body && response.body.errors) {
          reasons = response.body.errors
        }
      }

      const errors = {}
      errors[field] = []
      for (const bag in reasons) {
        let messages
        if (!Array.isArray(reasons[bag])) {
          messages = [reasons[bag]]
        } else {
          messages = reasons[bag]
        }

        messages.forEach((message) => {
          errors[field].push(`api.${message}.${bag}`)
        })
      }

      if (observer) {
        observer.setErrors(errors)
      }
    },
    handleErrors (response) {
      if (!response) {
        // show a pop-up
        this.$events.$emit('open:popup', ['api.unknown.general'])
        return
      }

      if (response.status === 0) {
        // show a pop-up
        this.$events.$emit('open:popup', ['api.network.general'])
        return
      }

      if (response.status === 503) {
        // show a pop-up
        this.$events.$emit('open:popup', ['api.network.maintenance'])
        return
      }

      if (response.status === 401) {
        // store the page to be redirected back to
        this.$store.dispatch('set_redirect', this.$route.path)

        this.$store.dispatch('user_logout')

        // session expired
        this.$router.push({ name: 'UsersLogin', params: { action: 'login' } })
        return
      }

      const reasons = (response && response.body && response.body.errors) ? response.body.errors : { general: ['unknown'] }
      const general = []
      for (const bag in reasons) {
        let messages
        if (!Array.isArray(reasons[bag])) {
          messages = [reasons[bag]]
        } else {
          messages = reasons[bag]
        }

        messages.forEach((message) => {
          // if (bag !== 'general' && this.hasOwnProperty(bag)) {
          //   this.errors.add({ field: bag, msg: message })
          // } else {
          //   general.push(message)
          // }
          general.push(`api.${message}.${bag}`)
        })
      }

      // show a pop-up
      this.$events.$emit('open:popup', general)
    }
  }
}
