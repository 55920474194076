/* eslint-disable camelcase */
import { configure, extend, setInteractionMode } from 'vee-validate'
import { required, email, min, max, alpha_dash, confirmed } from 'vee-validate/dist/rules'
import { i18n } from '@/config/i18n'

setInteractionMode('eager')

configure({
  // this will be used to generate messages.
  defaultMessage: (_, values) => i18n.t(`validation.messages.${values._rule_}`, values),
  classes: {
    invalid: 'is-invalid'
  }
})

// Install required rule and message.
extend('required', required)

// Install email rule and message.
extend('email', email)

// Install min rule and message.
extend('min', min)

// Install max rule and message.
extend('max', max)

// Install alpha_dash rule and message.
extend('alpha_dash', alpha_dash)

// Install confirmed rule and message.
extend('confirmed', confirmed)
