import firebase from 'firebase/app'
import 'firebase/messaging'

function install (Vue) {
  if (!('Notification' in window) || !firebase.messaging.isSupported()) {
    Vue.messaging = null
    Vue.prototype.$messaging = null
    return
  }

  firebase.initializeApp({
    projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
    apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
    messagingSenderId: process.env.VUE_APP_FIREBASE_SENDER_ID,
    appId: process.env.VUE_APP_FIREBASE_APP_ID,
    measurementId: process.env.VUE_APP_GA_ID
  })

  const messaging = firebase.messaging()

  Vue.messaging = messaging
  Vue.prototype.$messaging = messaging
}

export default install
