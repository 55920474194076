export default {
  SCHEMA: [
    {
      name: 'name',
      type: 'text'
    },
    {
      name: 'settings',
      type: 'object',
      SCHEMA: [
        {
          name: 'notifications_email',
          type: 'bool',
          default: true
        },
        {
          name: 'notifications_push',
          type: 'bool',
          default: true
        },
        {
          name: 'emails_frequency',
          type: 'integer',
          min: 0,
          max: 24,
          default: 24
        },
        {
          name: 'push_frequency',
          type: 'integer',
          min: 0,
          max: 24,
          default: 0
        }
      ]
    },
    {
      name: 'age',
      type: 'age',
      min: 17,
      max: 40,
      group: 'filter'
    },
    {
      name: 'form',
      key: 'skill',
      type: 'range',
      min: 1,
      max: 8,
      group: 'filter'
    },
    {
      name: 'experience',
      key: 'skill',
      type: 'range',
      max: 25,
      group: 'filter'
    },
    {
      name: 'leadership',
      key: 'skill',
      type: 'range',
      min: 1,
      max: 8,
      group: 'filter'
    },
    {
      name: 'stamina_skill',
      key: 'skill',
      type: 'range',
      max: 9,
      group: 'filter'
    },
    {
      name: 'keeper_skill',
      key: 'skill',
      type: 'range',
      max: 25,
      group: 'filter'
    },
    {
      name: 'playmaker_skill',
      key: 'skill',
      type: 'range',
      max: 25,
      group: 'filter'
    },
    {
      name: 'scorer_skill',
      key: 'skill',
      type: 'range',
      max: 25,
      group: 'filter'
    },
    {
      name: 'passing_skill',
      key: 'skill',
      type: 'range',
      max: 25,
      group: 'filter'
    },
    {
      name: 'winger_skill',
      key: 'skill',
      type: 'range',
      max: 25,
      group: 'filter'
    },
    {
      name: 'defender_skill',
      key: 'skill',
      type: 'range',
      max: 25,
      group: 'filter'
    },
    {
      name: 'set_pieces_skill',
      key: 'skill',
      type: 'range',
      max: 25,
      group: 'filter'
    },
    {
      name: 'agreeability',
      key: 'agreeability',
      type: 'range',
      max: 5,
      group: 'filter'
    },
    {
      name: 'aggressiveness',
      key: 'aggressiveness',
      type: 'range',
      max: 5,
      group: 'filter'
    },
    {
      name: 'honesty',
      key: 'honesty',
      type: 'range',
      max: 5,
      group: 'filter'
    },
    {
      name: 'htms',
      type: 'integer',
      min: 0,
      max: 3000,
      group: 'filter'
    },
    {
      name: 'htms28',
      type: 'integer',
      min: 0,
      max: 3000,
      group: 'filter'
    },
    {
      name: 'tsi',
      type: 'integer',
      min: 0,
      max: 100000,
      group: 'filter'
    },
    {
      name: 'salary',
      type: 'money',
      min: 0,
      max: 25000,
      group: 'filter'
    },
    {
      name: 'price',
      type: 'money',
      min: 0,
      max: 100000,
      group: 'filter'
    },
    {
      name: 'native_country_id',
      type: 'country',
      group: 'filter'
    },
    {
      name: 'NT_player',
      key: 'national_team', // used in translations
      type: 'choice',
      options: [0, 1, 2], // values of player national team attribute
      group: 'filter'
    },
    {
      name: 'specialties',
      key: 'specialty', // used in translations
      type: 'multichoice',
      options: [0, 1, 2, 3, 4, 5, 6, 8], // values of player specialty
      group: 'filter'
    }
  ]
}
