// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import BootstrapVue from 'bootstrap-vue'
import Toasted from 'vue-toasted'
import VueEvents from 'vue-events'
import VueGtag from 'vue-gtag'
import VueResource from 'vue-resource'
import VueStorage from 'vue-ls'

import App from '@/components/App/main'

import router from '@/config/router'
import store from '@/config/store'
import { i18n } from '@/config/i18n'
import '@/config/vee-validate'
import HelpersPlugin from '@/plugins/helpers/index'
import MessagingPlugin from '@/plugins/messaging/index'

Vue.config.productionTip = false

Vue.use(require('vue-script2'))

Vue.use(BootstrapVue)
Vue.use(Toasted)
Vue.use(VueGtag, {
  config: { id: process.env.VUE_APP_GA_ID }
}, router)
Vue.use(VueEvents)
Vue.use(VueResource)
Vue.use(VueStorage)
Vue.use(HelpersPlugin)
Vue.use(MessagingPlugin)

Vue.http.options.root = process.env.VUE_APP_API_URL

// load user from localStorage on page refresh
if (!store.state.user || !store.state.token) {
  const token = Vue.ls.get('token', null)
  const exp = Vue.ls.get('exp', null)
  const user = Vue.ls.get('user', null)
  store.commit('setUser', { token, exp, user })
}

// load settings from localStorage on page refresh
if (!store.state.countries) {
  const countries = Vue.ls.get('countries', [])
  store.commit('setCountries', countries)
}

// protect private routes
router.beforeEach((to, from, next) => {
  if (!to.meta || !to.meta.guards) {
    next()
    return
  }

  if (to.meta.guards === 1) {
    if (store.state.token && store.state.user) {
      if (!store.state.user.ht_user_id && to.name !== 'UsersSync') {
        // redirect to authorization page
        next({ name: 'UsersSync' })
      } else {
        next()
      }
    } else {
      // store the page to be redirected back to
      store.dispatch('set_redirect', to.path)
      // redirect to login page
      next({ name: 'UsersLogin', params: { action: 'login' } })
    }

    return
  }

  if (to.meta.guards === 2) {
    if (store.state.token && store.state.user) {
      next({ name: 'Home' })
    } else {
      next()
    }
  }
})

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
