import Dropdown from 'vue-simple-search-dropdown'
import find from 'lodash.find'
import sortBy from 'lodash.sortby'

export default {
  name: 'language-switcher',
  components: {
    Dropdown
  },
  props: ['id', 'value', 'items'],

  data () {
    return {
      propId: this.id || 'language-switcher'
    }
  },

  created () {
  },

  computed: {
    defaultValue () {
      return this.getLanguageById(this.value)
    },
    sortedItems () {
      return sortBy(this.items, this.sorter)
    }
  },

  methods: {
    handleInput (v) {
      if (v.id) {
        this.$emit('input', v)
      }
    },
    getLanguageById (id) {
      return find(this.items, ['id', id])
    },
    sorter (item) {
      return item.id === this.value ? 0 : 1
    }
  }
}
