import { register } from 'register-service-worker'

import FooterMenu from '@/components/FooterMenu/main'
import HeaderMenu from '@/components/HeaderMenu/main'
import PopUp from '@/components/PopUp/main'
import globalHelper from '@/mixins/global'

export default {
  name: 'app',
  components: {
    FooterMenu,
    HeaderMenu,
    PopUp
  },
  mixins: [globalHelper],
  data () {
    return {
      show: false,
      messages: []
    }
  },
  created () {
    this.$events.$on('open:popup', this.open)

    if (!('serviceWorker' in navigator)) {
      this.prepareSw(null)
      return
    }

    this.$store.subscribe((mutation, state) => {
      switch (mutation.type) {
        case 'setGetDeviceReady':
          if (state.get_device_ready) {
            this.getDeviceToken()
          }
          break
      }
    })

    const self = this
    // register the service-worker
    register(`${process.env.BASE_URL}service-worker.js`, {
      ready () {
      },
      registered (registration) {
        self.prepareSw(registration)
      },
      cached () {
      },
      updatefound () {
      },
      updated (registration) {
        const worker = registration.waiting
        worker.postMessage({ action: 'skipWaiting' })
      },
      offline () {
      },
      error () {
      }
    })
  },
  beforeDestroy () {
    this.$events.$off('open:popup')
  },
  computed: {
  },
  watch: {
  },
  methods: {
    close () {
      this.show = false
      this.messages = []
    },
    open (messages) {
      this.messages = messages
      this.show = true
    },
    prepareSw (registration) {
      if (!registration || !this.$messaging) {
        this.$store.dispatch('device_set', false)
        return
      }

      this.$store.dispatch('update_sw_registration', registration)

      // register a message listener for when the application is in foreground
      this.$messaging.onMessage((payload) => {
        if (payload.data && payload.data.body) {
          this.$toasted.info(payload.data.body).goAway(15000)
        }
      })
    },
    getDeviceToken () {
      Notification.requestPermission()
        .then(
          (response) => {
            if (response === 'denied') {
              this.$store.dispatch('device_set', false)
            } else {
              this.$store.dispatch('device_get')
                .then(
                  (response) => {
                    // register user device
                    this.$store.dispatch('user_device_register', response)
                  },
                  () => {
                    this.$store.dispatch('device_set', false)
                  })
            }
          },
          () => {
            // do not catch errors from the success handler. they are handled there
            this.$store.dispatch('device_set', false)
          })
    }
  }
}
