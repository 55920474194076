import LanguageSwitcher from '@/components/LanguageSwitcher/main'
import globalHelper from '@/mixins/global'
import { loadLanguageAsync, languages } from '@/config/i18n'

export default {
  name: 'header-menu',
  components: {
    LanguageSwitcher
  },
  mixins: [globalHelper],

  data () {
    return {
      availableLocales: languages
    }
  },

  methods: {
    logout () {
      this.$store.dispatch('user_logout').then(
        (response) => {
          this.$router.push({ name: 'UsersLogin', params: { action: 'login' } })
        },
        (response) => {
          // show a pop-up
          this.$events.$emit('open:popup', response.body)
        }
      )
    },
    handleLanguageChange (languageObject) {
      loadLanguageAsync(languageObject).catch(
        () => { this.$events.$emit('open:popup', ['general.errors.language.load']) })
    }
  }
}
