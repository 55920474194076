export default {
  general: {
    username: 'Username',
    password: 'Password',
    newpass: 'New Password',
    currentpass: 'Current Password',
    c_password: 'Confirm Password',
    c_newpass: 'Confirm New Password',
    email: 'Email Address',
    language: 'Language',
    subject: 'Subject',
    message: 'Message',
    filtername: 'Filter Name',
    placeholders: {
      username: 'Enter username',
      password: 'Enter password',
      newpass: 'Enter new password',
      currentpass: 'Enter current password',
      c_password: 'Confirm password',
      c_newpass: 'Confirm new password',
      email: 'Enter email',
      subject: 'Enter subject',
      message: 'Enter your message',
      filtername: 'Enter a name'
    },
    errors: {
      language: {
        load: 'Failed switching the language.'
      }
    },
    here: 'here',
    ok: 'Ok',
    cancel: 'Cancel',
    on: 'on',
    off: 'off',
    min: 'Minimum value',
    max: 'Maximum value',
    years: '{n} year | {n} years',
    days: '{n} day | {n} days',
    type_for_more: 'Type to see more...'
  },
  notifications: {
    disabled: 'The {notifications} are blocked. If you did not block them, consider using another browser.',
    notifications: 'notifications',
    title: 'Notification Settings',
    form: {
      email: 'Email notifications',
      email_frequency: 'Email notifications frequency',
      browser: 'Browser notifications',
      browser_frequency: 'Browser notifications frequency',
      frequency: 'instantly | once every {n} hour | once every {n} hours',
      help: {
        email: 'You will receive players related notifications via email.',
        browser: 'You will receive players related notifications directly in your browser.',
        frequency: 'Recommended value is \'instantly\' to avoid missing some players.',
        blocked: 'You\'ve blocked notifications or this browser don\'t support them.'
      }
    }
  },
  header: {
    menu: {
      home: 'Home',
      account: 'Account',
      signin: 'Sign In',
      register: 'Register',
      inbox: 'Inbox',
      blog: 'Blog',
      contact: 'Contact Us',
      logout: 'Logout'
    }
  },
  footer: {
    menu: {
      privacy: 'Privacy',
      terms: 'Terms'
    }
  },
  login: {
    title: 'Sign In',
    signin: 'Sign In',
    register: 'Register',
    forgot: 'Forgot Password?',
    form: {
      submit: 'Sign In',
      remember: 'Remember me'
    },
    info: {
      what: 'What Does Mercattrick Do?',
      purpose: 'The purpose of Mercattrick is to:',
      purpose1: 'help Hattrick <strong>managers</strong> that <strong>sell</strong> players get a better exposure for these players and thus get the most of it;',
      purpose2: 'help Hattrick <strong>managers</strong> that are <strong>interested in buying</strong> certain types of players receive notifications when interesting additions are made to the transfer list;',
      how: 'How Does It Work?',
      buyers: 'Buyers',
      step1: 'login and configure a set of minimum player characteristics (filters) for which they want to be notified when on sale.',
      sellers: 'Sellers',
      step2: 'list a player for sale in HT, then login here and "promote" their player.',
      promoting: 'Promoting',
      step3: 'a player means that all "buyers" interested in that type of player will be notified that it\'s for sale.'
    }
  },
  register: {
    title: 'Register',
    form: {
      registered: 'Your account has been created. Click {here} to continue.',
      submit: 'Register',
      agreement: 'By clicking @:register.form.submit you agree to the {terms} and {privacy} and to occasionally receive account related emails.',
      tos: 'terms of service',
      privacy: 'privacy statement',
      help: {
        username: 'This is not your HT username.',
        password: 'Enter a minimum of 6 characters. The password is case sensitive.',
        email: 'We\'ll use this email address to notify you about new players.'
      }
    }
  },
  recover: {
    title: 'Recover Password',
    form: {
      recovered: 'An email with a reset link has been sent to your email address.',
      submit: 'Recover',
      help: {
        email: 'This must be the email address associated with your account.'
      }
    }
  },
  reset: {
    title: 'Reset Password',
    form: {
      submit: 'Reset',
      help: {
        email: 'This must be the email address associated with your account.',
        password: 'Enter a minimum of 6 characters. The password is case sensitive.'
      }
    }
  },
  contact: {
    title: 'Contact Us',
    form: {
      success: 'The message has been sent successfully.',
      submit: 'Send',
      help: {
        email: 'We\'ll use this email address only to reply to you.'
      }
    }
  },
  sync: {
    users: {
      syncing: 'Sync-ing user details...',
      fail: 'User sync failed.',
      retry: 'Try again',
      authorize: 'You need to authorize this CHPP product. Click {here} to open the Hattrick website.',
      authorizing: 'Initializing the authorization. You\'ll be redirected to <strong>hattrick.org</strong> shortly...',
      finalize: 'Finalizing the authorization...'
    },
    system: {
      syncing: 'Sync-ing system details...',
      fail: 'System sync failed. {retry} or {skip}!',
      retry: 'Try Again',
      skip: 'Skip it'
    }
  },
  account: {
    account: {
      title: 'Account Settings',
      form: {
        success: 'The account has been updated successfully.',
        submit: 'Save',
        help: {
          username: 'This is not your HT username.',
          email: 'We\'ll use this email address to notify you about new players.'
        }
      }
    },
    login: {
      title: 'Login Settings',
      form: {
        success: 'The password has been updated successfully.',
        submit: 'Save',
        help: {
          passwords: 'Type in your current password, new password and the confirmation password.'
        }
      }
    },
    privacy: {
      title: 'Privacy Settings',
      form: {
        submit: 'Delete Account',
        delete: 'Delete',
        help: {
          warning: 'This action is permanent. All your data will be erased.',
          confirmation: 'Please confirm you want to permanently delete your account.'
        }
      }
    }
  },
  filter: {
    title: 'Tap to add/remove a filter',
    form: {
      submit: 'Save',
      set: 'Set',
      remove: 'Remove',
      help: {
        name: 'Give your filter a name to better identify it.'
      }
    },
    tooltip: {
      save: 'You need to set at least one filtering criteria!'
    },
    item: {
      tooltip: 'Click \'@:filter.item.show_players\' to display the list.',
      show_players: 'Show Players',
      hide_players: 'Hide Players',
      players: 'player found | players found',
      form: {
        processing: 'processing',
        fetch: 'Fetch HT players',
        edit: 'Edit',
        delete: 'Delete',
        help: {
          confirm: 'Are you sure you want to delete this filter?'
        }
      }
    }
  },
  home: {
    myplayers: {
      title: 'My Transfers',
      loading: 'Loading players...',
      none: 'You don\'t have any players for sale, at this moment.',
      promoted: 'promoted',
      late: 'too late to promote',
      form: {
        reload: 'Reload',
        promote: 'Promote'
      },
      tooltip: 'Promote your players for others to see them.'
    },
    bookmarks: {
      title: 'Bookmarks',
      loading: 'Loading players...',
      none: 'You don\'t have any bookmarked players, at this moment.',
      form: {
        reload: 'Reload',
        unbookmark: 'Un-Bookmark',
        help: {
          confirm: 'Are you sure you want to remove this bookmark?'
        }
      },
      tooltip: 'You\'ll receive notifications about these players when they are close to the deadline.'
    },
    filters: {
      title: 'My Filters',
      loading: 'Loading filters...',
      none: 'You don\'t have any filters. You should create some.',
      form: {
        reload: 'Reload',
        add: 'Add Filter',
        bookmark: 'Bookmark',
        hide: 'Hide',
        help: {
          confirm: 'Are you sure you want to hide this player?'
        }
      },
      tooltip: 'Bookmark interesting players from these lists.'
    }
  },

  player: {
    attributes: {
      name: 'Name',
      age: 'Age',
      form: 'Form',
      experience: 'Experience',
      leadership: 'Leadership',
      stamina_skill: 'Stamina',
      keeper_skill: 'Keeper',
      playmaker_skill: 'Playmaker',
      scorer_skill: 'Scorer',
      passing_skill: 'Passing',
      winger_skill: 'Winger',
      defender_skill: 'Defender',
      set_pieces_skill: 'Set Pieces',
      agreeability: 'Agreeability',
      aggressiveness: 'Aggressiveness',
      honesty: 'Honesty',
      htms: 'HTMS',
      htms28: 'HTMS28',
      tsi: 'TSI',
      salary: 'Salary',
      price: 'Price',
      asking_price: 'Asking Price',
      highest_bid: 'Highest Bid',
      native_country: 'Native Country',
      native_country_id: 'Native Country',
      NT_player: 'National Team Player',
      specialties: 'Specialties',
      specialty: 'Specialty'
    },
    redcard: 'Red Card',
    yellowcard: 'Yellow Card',
    bruised: 'Bruised',
    injured: 'Injured',
    injury: '{n} week | {n} weeks',
    fullage: '{years} years and {days} days',
    deadline: 'Deadline',
    htms: 'HTMS/HTMS28',
    more: 'Show more',
    less: 'Show less',
    levels: {
      skill: [
        'non-existent',
        'disastrous',
        'wretched',
        'poor',
        'weak',
        'inadequate',
        'passable',
        'solid',
        'excellent',
        'formidable',
        'outstanding',
        'brilliant',
        'magnificent',
        'world class',
        'supernatural',
        'titanic',
        'extra-terrestrial',
        'mythical',
        'magical',
        'utopian',
        'divine',
        'divine (+1)',
        'divine (+2)',
        'divine (+3)',
        'divine (+4)',
        'divine (+5)'
      ],
      agreeability: [
        'nasty fellow',
        'controversial person',
        'pleasant guy',
        'sympathetic guy',
        'popular guy',
        'beloved team member'
      ],
      aggressiveness: [
        'tranquil',
        'calm',
        'balanced',
        'temperamental',
        'fiery',
        'unstable'
      ],
      honesty: [
        'infamous',
        'dishonest',
        'honest',
        'upright',
        'righteous',
        'saintly'
      ],
      specialty: [
        'none',
        'technical',
        'quick',
        'powerful',
        'unpredictable',
        'head specialist',
        'resilient',
        '',
        'support'
      ],
      national_team: [
        'never',
        'currently no',
        'currently yes'
      ],
      any: 'any/none'
    },
    tooltips: {
      highest_bid: 'The highest bid can be outdated!'
    }
  },
  countries: {
    1: 'Sweden',
    2: 'England',
    3: 'Germany',
    4: 'Italy',
    5: 'France',
    6: 'Mexico',
    7: 'Argentina',
    8: 'USA',
    9: 'Norway',
    10: 'Denmark',
    11: 'Finland',
    12: 'Netherlands',
    13: 'Oceania',
    14: 'Canada',
    15: 'Scotland',
    16: 'Ireland',
    17: 'Chile',
    18: 'Colombia',
    19: 'Uruguay',
    20: 'Venezuela',
    21: 'Peru',
    22: 'Brazil',
    23: 'Portugal',
    24: 'South Africa',
    25: 'Japan',
    26: 'Poland',
    27: 'India',
    28: 'China',
    29: 'South Korea',
    30: 'Thailand',
    31: 'Turkey',
    32: 'Egypt',
    33: 'Austria',
    34: 'Russia',
    35: 'Spain',
    36: 'Romania',
    37: 'Iceland',
    38: 'Belgium',
    39: 'Malaysia',
    40: 'Switzerland',
    41: 'Singapore',
    42: 'Croatia',
    43: 'Serbia',
    44: 'Hungary',
    45: 'Greece',
    46: 'Czech Republic',
    47: 'Estonia',
    48: 'Latvia',
    49: 'Indonesia',
    50: 'Philippines',
    51: 'Israel',
    52: 'Chinese Taipei',
    53: 'Hong Kong',
    55: 'Bulgaria',
    56: 'Wales',
    57: 'Slovenia',
    61: 'Lithuania',
    62: 'Ukraine',
    63: 'Bosnia and Herzegovina',
    64: 'Pakistan',
    65: 'Vietnam',
    66: 'Slovakia',
    67: 'Paraguay',
    68: 'Ecuador',
    69: 'Bolivia',
    70: 'Nigeria',
    71: 'Faroe Islands',
    72: 'Morocco',
    75: 'Saudi Arabia',
    76: 'Tunisia',
    77: 'Costa Rica',
    78: 'United Arab Emirates',
    79: 'Luxembourg',
    80: 'Iran',
    82: 'Cyprus',
    83: 'Dominican Republic',
    86: 'Senegal',
    87: 'Belarus',
    88: 'Northern Ireland',
    89: 'Jamaica',
    90: 'Kenya',
    91: 'Panama',
    92: 'Macedonia',
    93: 'Cuba',
    94: 'Albania',
    95: 'Honduras',
    96: 'El Salvador',
    97: 'Malta',
    98: 'Kyrgyzstan',
    99: 'Moldova',
    100: 'Georgia',
    101: 'Andorra',
    102: 'Guatemala',
    103: 'Jordan',
    104: 'Armenia',
    105: 'Trinidad & Tobago',
    121: 'Nicaragua',
    122: 'Kazakhstan',
    123: 'Suriname',
    125: 'Liechtenstein',
    126: 'Algeria',
    127: 'Mongolia',
    128: 'Lebanon',
    129: 'Bahrain',
    130: 'Barbados',
    131: 'Cape Verde',
    132: 'Ivory Coast',
    133: 'Azerbaijan',
    134: 'Kuwait',
    135: 'Iraq',
    136: 'Montenegro',
    137: 'Angola',
    138: 'Bangladesh',
    139: 'Yemen',
    140: 'Oman',
    142: 'Mozambique',
    143: 'Brunei',
    144: 'Ghana',
    145: 'Cambodia',
    147: 'Benin',
    148: 'Syria',
    149: 'Qatar',
    150: 'Tanzania',
    153: 'Uganda',
    154: 'Maldives',
    163: 'Uzbekistan',
    165: 'Cameroon',
    166: 'Palestine',
    175: 'Sri Lanka',
    177: 'Sao Tome and Principe',
    178: 'Curacao',
    179: 'Guam',
    180: 'Comoros',
    181: 'DR Congo',
    182: 'Ethiopia',
    183: 'Madagascar',
    184: 'Saint Vincent and the Grenadines',
    185: 'Botswana',
    186: 'Belize',
    187: 'Zambia',
    188: 'Haiti',
    189: 'Myanmar',
    190: 'Puerto Rico',
    191: 'San Marino',
    192: 'Nepal',
    193: 'Burkina Faso',
    194: 'Grenada',
    196: 'Tahiti',
    197: 'Guyana'
  },

  validation: {
    attributes: {},
    messages: {
      alpha_num: 'This field may only contain alpha-numeric characters.',
      alpha_dash: 'This field may only contain alpha-numeric characters, underscores or dashes.',
      confirmed: 'The confirmation does not match.',
      email: 'This field must be a valid email.',
      max: 'This field contains too many characters.',
      min: 'This field contains too few characters.',
      required: 'This field is required.'
    }
  },

  api: {
    validation: {
      string: {
        subject: 'The subject is required.',
        body: 'The content is required.',
        username: 'The username is required.',
        password: 'The password is required.',
        locale: 'The language is required.',
        name: 'The name is required.',
        token: 'The token is required.'
      },
      integer: {
        native_country_id: 'The country id must be a number.',
        htms_min: 'The minimum HTMS value must be a number.',
        htms_max: 'The maximum HTMS value must be a number.',
        htms28_min: 'The minimum HTMS28 value must be a number.',
        htms28_max: 'The maximum HTMS28 value must be a number.',
        tsi_min: 'The minimum TSI value must be a number.',
        tsi_max: 'The maximum TSI value must be a number.'
      },
      numeric: {
        age_min: 'The minimum age must be a number.',
        age_max: 'The maximum age must be a number.',
        salary_min: 'The minimum salary value must be a number.',
        salary_max: 'The maximum salary value must be a number.',
        price_min: 'The minimum price value must be a number.',
        price_max: 'The maximum price value must be a number.'
      },
      required: {
        subject: 'The subject is required.',
        body: 'The content is required.',
        username: 'The username is required.',
        password: 'The password is required.',
        current_password: 'The current password is required.',
        new_password: 'The new password is required.',
        email: 'The email is required.',
        token: 'The token is required.',
        endpoint: 'The \'endpoint\' parameter is required.',
        url: 'The \'URL\' parameter is required.',
        oauth_token: 'The \'oauth_token\' parameter is required.',
        oauth_verifier: 'The \'oauth_verifier\' parameter is required.'
      },
      max: {
        age_min: 'The minimum age must be lower than 100.',
        age_max: 'The maximum age must be lower than 100.',
        htms_min: 'The minimum HTMS value must be lower than 12,000.',
        htms_max: 'The maximum HTMS value must be lower than 12,000.',
        htms28_min: 'The minimum HTMS28 value must be lower than 12,000.',
        htms28_max: 'The maximum HTMS28 value must be lower than 12,000.',
        tsi_min: 'The minimum ITC value must be lower than 16,000,000.',
        tsi_max: 'The maximum ITC value must be lower than 16,000,000.',
        subject: 'The subject contains too many characters.',
        username: 'The username contains too many characters.',
        email: 'The email contains too many characters.',
        locale: 'The language contains too many characters.',
        name: 'The name contains too many characters.',
        token: 'The token contains too many characters.',
        NT_player: 'The national team player value must be maximum 2 (\'@:player.levels.national_team[2]\').',

        string: {
          subject: '@:api.validation.max.subject',
          username: '@:api.validation.max.username',
          email: '@:api.validation.max.email',
          locale: '@:api.validation.max.locale',
          name: '@:api.validation.max.name',
          token: '@:api.validation.max.token',
          NT_player: '@:api.validation.max.NT_player'
        },

        numeric: {
          age_min: '@:api.validation.max.age_min',
          age_max: '@:api.validation.max.age_max',
          htms_min: '@:api.validation.max.htms_min',
          htms_max: '@:api.validation.max.htms_max',
          htms28_min: '@:api.validation.max.htms28_min',
          htms28_max: '@:api.validation.max.htms28_max',
          tsi_min: '@:api.validation.max.tsi_min',
          tsi_max: '@:api.validation.max.tsi_max',
          NT_player: '@:api.validation.max.NT_player'
        }
      },
      min: {
        password: 'The password contains too few characters.',
        age_min: 'The minimum age must be greater than 17.',
        age_max: 'The maximum age must be greater than 17.',
        native_country_id: 'The country id must be greater than 0.',
        htms_min: 'The minimum HTMS value must be a positive number.',
        htms_max: 'The maximum HTMS value must be a positive number.',
        htms28_min: 'The minimum HTMS28 value must be greater than -12,000.',
        htms28_max: 'The maximum HTMS28 value must be greater than -12,000.',
        tsi_min: 'The minimum TSI value must be a positive number.',
        tsi_max: 'The maximum TSI value must be a positive number.',
        salary_min: 'The minimum salary value must be a positive number.',
        salary_max: 'The maximum salary value must be a positive number.',
        price_min: 'The minimum price value must be a positive number.',
        price_max: 'The maximum price value must be a positive number.',
        NT_player: 'The national team player value must be minimum 0 (\'@:player.levels.national_team[0]\').',

        string: {
          password: '@:api.validation.min.password',
          age_min: '@:api.validation.min.age_min',
          age_max: '@:api.validation.min.age_max',
          native_country_id: '@:api.validation.min.native_country_id',
          htms_min: '@:api.validation.min.htms_min',
          htms_max: '@:api.validation.min.htms_max',
          htms28_min: '@:api.validation.min.htms28_min',
          htms28_max: '@:api.validation.min.htms28_max',
          tsi_min: '@:api.validation.min.tsi_min',
          tsi_max: '@:api.validation.min.tsi_max',
          salary_min: '@:api.validation.min.salary_min',
          salary_max: '@:api.validation.min.salary_max',
          price_min: '@:api.validation.min.price_min',
          price_max: '@:api.validation.min.price_max',
          NT_player: '@:api.validation.min.NT_player'
        },

        numeric: {
          age_min: '@:api.validation.min.age_min',
          age_max: '@:api.validation.min.age_max',
          native_country_id: '@:api.validation.min.native_country_id',
          htms_min: '@:api.validation.min.htms_min',
          htms_max: '@:api.validation.min.htms_max',
          htms28_min: '@:api.validation.min.htms28_min',
          htms28_max: '@:api.validation.min.htms28_max',
          tsi_min: '@:api.validation.min.tsi_min',
          tsi_max: '@:api.validation.min.tsi_max',
          salary_min: '@:api.validation.min.salary_min',
          salary_max: '@:api.validation.min.salary_max',
          price_min: '@:api.validation.min.price_min',
          price_max: '@:api.validation.min.price_max',
          NT_player: '@:api.validation.min.NT_player'
        }
      },
      email: {
        email: 'The email field must contain a valid email address.'
      },
      url: {
        url: 'The \'URL\' parameter must contain a valid URL.'
      },
      in: {
        endpoint: 'The \'endpoint\' parameter must be valid.',
        form_min: 'The minimum form value must be between 1 (\'@:player.levels.skill[1]\') and 8 (\'@:player.levels.skill[8]\').',
        form_max: 'The maximum form value must be between 1 (\'@:player.levels.skill[1]\') and 8 (\'@:player.levels.skill[8]\').',
        agreeability_min: 'The minimum agreeability value must be between 0 (\'@:player.levels.agreeability[0]\') and 5 (\'@:player.levels.agreeability[5]\').',
        agreeability_max: 'The maximum agreeability value must be between 0 (\'@:player.levels.agreeability[0]\') and 5 (\'@:player.levels.agreeability[5]\').',
        aggressiveness_min: 'The minimum aggressiveness value must be between 0 (\'@:player.levels.aggressiveness[0]\') and 5 (\'@:player.levels.aggressiveness[5]\').',
        aggressiveness_max: 'The maximum aggressiveness value must be between 0 (\'@:player.levels.aggressiveness[0]\') and 5 (\'@:player.levels.aggressiveness[5]\').',
        honesty_min: 'The minimum honesty value must be between 0 (\'@:player.levels.honesty[0]\') and 5 (\'@:player.levels.honesty[5]\').',
        honesty_max: 'The maximum honesty value must be between 0 (\'@:player.levels.honesty[0]\') and 5 (\'@:player.levels.honesty[5]\').',
        experience_min: 'The minimum experience value must be between 0 (\'@:player.levels.skill[0]\') and 25 (\'@:player.levels.skill[25]\').',
        experience_max: 'The maximum experience value must be between 0 (\'@:player.levels.skill[0]\') and 25 (\'@:player.levels.skill[25]\').',
        leadership_min: 'The minimum leadership value must be between 1 (\'@:player.levels.skill[1]\') and 8 (\'@:player.levels.skill[8]\').',
        leadership_max: 'The maximum leadership value must be between 1 (\'@:player.levels.skill[1]\') and 8 (\'@:player.levels.skill[8]\').',
        stamina_skill_min: 'The minimum stamina value must be between 0 (\'@:player.levels.skill[0]\') and 9 (\'@:player.levels.skill[9]\').',
        stamina_skill_max: 'The maximum stamina value must be between 0 (\'@:player.levels.skill[0]\') and 9 (\'@:player.levels.skill[9]\').',
        keeper_skill_min: 'The minimum keeper value must be between 0 (\'@:player.levels.skill[0]\') and 25 (\'@:player.levels.skill[25]\').',
        keeper_skill_max: 'The maximum keeper value must be between 0 (\'@:player.levels.skill[0]\') and 25 (\'@:player.levels.skill[25]\').',
        playmaker_skill_min: 'The minimum playmaker value must be between 0 (\'@:player.levels.skill[0]\') and 25 (\'@:player.levels.skill[25]\').',
        playmaker_skill_max: 'The maximum playmaker value must be between 0 (\'@:player.levels.skill[0]\') and 25 (\'@:player.levels.skill[25]\').',
        scorer_skill_min: 'The minimum scorer value must be between 0 (\'@:player.levels.skill[0]\') and 25 (\'@:player.levels.skill[25]\').',
        scorer_skill_max: 'The maximum scorer value must be between 0 (\'@:player.levels.skill[0]\') and 25 (\'@:player.levels.skill[25]\').',
        passing_skill_min: 'The minimum passing value must be between 0 (\'@:player.levels.skill[0]\') and 25 (\'@:player.levels.skill[25]\').',
        passing_skill_max: 'The maximum passing value must be between 0 (\'@:player.levels.skill[0]\') and 25 (\'@:player.levels.skill[25]\').',
        winger_skill_min: 'The minimum winger value must be between 0 (\'@:player.levels.skill[0]\') and 25 (\'@:player.levels.skill[25]\').',
        winger_skill_max: 'The maximum winger value must be between 0 (\'@:player.levels.skill[0]\') and 25 (\'@:player.levels.skill[25]\').',
        defender_skill_min: 'The minimum defender value must be between 0 (\'@:player.levels.skill[0]\') and 25 (\'@:player.levels.skill[25]\').',
        defender_skill_max: 'The maximum defender value must be between 0 (\'@:player.levels.skill[0]\') and 25 (\'@:player.levels.skill[25]\').',
        set_pieces_skill_min: 'The minimum set pieces value must be between 0 (\'@:player.levels.skill[0]\') and 25 (\'@:player.levels.skill[25]\').',
        set_pieces_skill_max: 'The maximum set pieces value must be between 0 (\'@:player.levels.skill[0]\') and 25 (\'@:player.levels.skill[25]\').'
      },
      exists: {
        user_id: 'The user does not exist any longer.'
      },
      boolean: {
        promoted: 'The \'promoted\' parameter must be true or false.',
        bookmarked: 'The \'bookmarked\' parameter must be true or false.',
        ignored: 'The \'ignored\' parameter must be true or false.',
        notifications_email: 'The \'notifications_email\' parameter must be true or false.',
        notifications_desktop: 'The \'notifications_desktop\' parameter must be true or false.'
      },
      array: {
        specialties: 'The specialties must contain an array of values.'
      }
    },
    unknown: {
      general: 'An unexpected error occurred. We suggest refreshing the page.'
    },
    network: {
      general: 'You\'re experiencing network connectivity issues.',
      maintenance: 'System maintenance. Retry in a few minutes.'
    },
    denied: {
      access: 'Access denied.'
    },
    expired: {
      token: 'Authentication Token has expired.',
      session: 'The user is not authenticated.'
    },
    failed: {
      registration: 'Unable to complete the registration.',
      user_details: 'Impossible to retrieve user details.',
      player_details: 'Impossible to retrieve player details.',
      filter_fetch: 'Impossible to fetch new players.',
      password_update: 'Password did not update.',
      chpp_connection: 'Impossible to initiate CHPP connection.',
      chpp_authorization: 'Unable to start the CHPP authorization.'
    },
    invalid: {
      credentials: 'Invalid credentials.',
      token: 'Authentication Token is invalid.',
      request: 'Invalid request.'
    },
    limit: {
      filters: 'You have reach the limit of filters that you can create.'
    },
    missing: {
      token: 'Authentication Token is missing.'
    },
    conflict: {
      username: 'Username in use.',
      username_password: 'Username and/or email already in use.'
    }
  }
}
